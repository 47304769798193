// 获取区域列表
export const areaList = `/reverse/areaList`;
// 获取诊所列表
export const clinicList = `/reverse/clinicList`;
// 获取时间表
export const calendar = `/reverse/calendar`;
// 提交个人预约信息
export const complete = `/reverse/complete`;
// 验证预约番号和邮箱
export const confirm = `/reverse/confirm`;
// 添加同行人信息
export const companion = `/reverse/companion`;
// 查询问卷调查
export const findQuestion = `/reverse/findQuestion`;
// 提交问卷
export const saveQuestion = `/reverse/saveQuestion`;
// 获取诊所接口
export const hopeChange = `/reverse/hopeChange`;
// 预约变更获取时间接口
export const getHopeTime = `/reverse/getHopeTime`;
// 提交变更的希望数据
export const submitHome = `/reverse/submitHome`;
// 取消预约
export const cancel = `/reverse/cancel`;
// 添加header body
export const header = `/reverse/header`;
// hash
export const login = `/reverse/login`;
// 礼品介绍 首页确认接口
export const homeConfirm = '/introPresent/index'
// 选择优惠礼品
export const choosePresent= '/introPresent/confirm'
// 优惠确认
export const presentConfirm = '/introPresent/complete'
// 咨询提交确认
export const contactConfirm = '/contact/confirm'
// 咨询确认提交
export const ontactSend = '/contact/send'