import { TIME_OUT } from "@/constants/config";
import Axios from "axios";
import store from "@/store";
const baseURL =
  // process.env.NODE_ENV === "development"
  //   ? "/api"
  //   : 
    process.env.VUE_APP_BASE_API;

const axiosInstance = Axios.create({
  timeout: TIME_OUT * 1000,
  baseURL: baseURL
});

axiosInstance.interceptors.request.use(
  config => {
    if (!config.noLoading) {
      store.commit("updateLoadingShow", true);
    }
    return config;
  }
  // error => {}
);

axiosInstance.interceptors.response.use(
  response => {
    let data = response.data;
    store.commit("updateLoadingShow", false);
    return data;
  },
  error => {
    store.commit("updateLoadingShow", false);
  }
);
export default axiosInstance;
