import Vue from "vue";
import Vuex from "vuex";
import $ from "jquery";
import router from "../router";

import { STORAGE_KEY } from "@/constants/config";
// vuex本地持久化
import createPersistedState from "vuex-persistedstate";
import createLogger from "vuex/dist/logger";

import { indexApi } from "@/api/module.js";

Vue.use(Vuex);
const plugins = [
  createPersistedState({
    key: STORAGE_KEY,
    paths: [
      "loadingShow",
      "completeInfo", // 预约接口参数
      "userInfo",
      "parnterInfo", // 同行人信息
      "completeRes", // 预约返回结果
      "havePartners", // 是否有同行人
      "counselingAnswer", // 调差问卷答案
      "hopeChangeInfo", // 希望变更的信息
      "jsTag", // 跟踪信息
      "hashInfo", // hash信息
      'presentsInfo', // IntroPresents登陆确认信息
      'presentsConfirmInfo', // 选择后的确认信息
      'contactParams', // contact 请求参数
      'IsPC', // 是否是pc
      'loginError', // 报错
      'xuid',
      'suid',
    ]
  })
];
process.env.NODE_ENV === "development" ? plugins.push(createLogger()) : null;
export default new Vuex.Store({
  plugins, // 刷新持久化处理
  state: {
    loadingShow: false,
    userInfo: null,
    parnterInfo: null,
    completeInfo: null,
    completeRes: null,
    hopeChangeInfo: null,
    havePartners: false,
    counselingAnswer: null,
    jsTag: null,
    hashInfo: null,
    presentsInfo: null,
    presentsConfirmInfo: null,
    contactParams: null,
    IsPC: true,
    loginError: '',
    xuid: '',
    suid: '',
  },
  mutations: {
    updateJsTag(state, jsTag) {
      Vue.set(state, "jsTag", jsTag);
    },
    updateLoadingShow(state, loadingShow) {
      Vue.set(state, "loadingShow", loadingShow);
    },
    updateCompleteInfo(state, completeInfo) {
      Vue.set(state, "completeInfo", completeInfo);
    },
    updateUserInfo(state, userInfo) {
      Vue.set(state, "userInfo", userInfo);
    },
    updateParnterInfo(state, parnterInfo) {
      Vue.set(state, "parnterInfo", parnterInfo);
    },
    updateCompleteRes(state, completeRes) {
      Vue.set(state, "completeRes", completeRes);
    },
    updateHavePartners(state, havePartners) {
      Vue.set(state, "havePartners", havePartners);
    },
    updateCounselingAnswer(state, counselingAnswer) {
      Vue.set(state, "counselingAnswer", counselingAnswer);
    },
    updateHopeChangeInfo(state, hopeChangeInfo) {
      Vue.set(state, "hopeChangeInfo", hopeChangeInfo);
    },
    updateHashInfo(state, hashInfo) {
      Vue.set(state, "hashInfo", hashInfo);
    },
    updatePresentsInfo(state, presentsInfo) {
      Vue.set(state, "presentsInfo", presentsInfo);
    },
    updatePresentsConfirmInfo(state, presentsConfirmInfo) {
      Vue.set(state, "presentsConfirmInfo", presentsConfirmInfo);
    },
    updateContactParams(state, contactParams) {
      Vue.set(state, "contactParams", contactParams);
    },
    updateXuid(state, xuid) {
      Vue.set(state, "xuid", xuid);
    },
    updateSuid(state, suid) {
      Vue.set(state, "suid", suid);
    },
    checkDevice(state) {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      // this.IsPC = flag;
      Vue.set(state, 'IsPC', flag)
      // console.log(flag,'ispc');
    },
    updateLoginError(state, loginError) {
      Vue.set(state, "loginError", loginError);
    },
    addTag(state, tag) {
      if (state.jsTag) {
        let head = document.head || document.getElementsByTagName("head")[0];
        let bodyTag = $(tag.bodyStr);
        head.innerHTML += tag.headStr;
        $("body").append(bodyTag);
      }
    }
  },
  actions: {
    getHashInfo({ commit }, hash,errorpage) {
      commit("updateHashInfo", null);
      indexApi
        .login({
          hash
        })
        .then(res => {
          if (res.code == 200) {
            if(res){
              commit("updateHashInfo", res.data);
            }
          }else{
            
          }
        }).catch(error => {
          return
        });
    }
  },
  modules: {}
});
